<template>
  <div>
    <div class="border-b border-gray-200">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav class="-mb-px block md:flex justify-between md:space-x-4">
          <div class="flex space-x-4 md:space-x-8" aria-label="Tabs">
            <a v-for="tab in tabs" @click="changeTab(tab.name)" :key="tab.name" :id="tab.name" href="#" :class="[tab.name === currentTab ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm']">
            {{ tab.label }}
            </a>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="">
    <div v-if="currentTab === 'youtube'" class="absolute opacity-0 pointer-events-none">
      <YouTube
        v-if="filteredFolders.length"
        :src="filteredFolders[0].folderContent.link"
        class="w-full-smart"
      />
    </div>
    <div class="max-w-7xl mx-auto py-6 px-4 sm:py-6 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <dl class="space-y-6 divide-gray-200">
          <Disclosure as="div" v-for="(folder) in filteredFolders" :key="folder.id" v-slot="{ open }" class="p-4 border border-gray-300 rounded-lg">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none">
                <span class="font-medium text-gray-900">
                  {{folder.folderName}}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-4">
              <ul v-if="currentTab === 'document'" class="divide-y divide-gray-200">
                <li v-for="(file,index) in folder.folderContent" :key="index" class="w-full py-3">
                  <a target="_blank" :href="file.link">
                    {{file.title}}
                  </a>
                </li>
              </ul>
              <ul v-if="currentTab === 'youtube'" class="divide-y divide-gray-200">
                <li v-for="(file,index) in folder.folderContent" :key="index" class="w-full py-3">
                  <p class="pb-3"> {{file.title}} </p>
                  <YouTube
                    v-if="file.link.includes('youtu')"
                    :src="file.link"
                    class="w-full-smart"
                  />
                  <video v-else controls class="w-full-smart object-contain" >
                    <source :src="file.link" :type="file.type" />
                  </video>
                </li>
              </ul>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import YouTube from 'vue3-youtube'
import { mapState } from 'vuex'
import { ref } from 'vue'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    YouTube,
  },
  setup() {
    const open = ref(true)
    return { open }
  },
  data(){
    return{
      currentTab: 'document'
    }
  },
  computed: {
    tabs () {
      return [
          { label: this.$t('settings.documents'), name: 'document'},
          { label: this.$t('settings.videos'), name: 'youtube'},
      ]
    },
    filteredFolders(){
      if(this.folders){
        return this.folders.filter((folder) => {
            return folder.contentType === this.currentTab
        })
      }
      else{
        return []
      }
    },
    ...mapState(["folders"])
  },
  async created(){
    console.log(this.folders)
    if(!this.folders){
      this.$store.dispatch('setLoading', true)
      await this.$store.dispatch("getFolders", this.$i18n.locale)
      this.$store.dispatch('setLoading', false)
    }
  },
  methods: {
    changeTab(tab){
      this.currentTab = tab
      this.$router.push({ hash: '#'+this.currentTab })
    }
  },
  watch: {
    "$i18n.locale": {
      async handler() {
        this.$store.dispatch('setLoading', true)
        await this.$store.dispatch("getFolders", this.$i18n.locale)
        this.$store.dispatch('setLoading', false)
      }
    },
    $route: {
      immediate: true,
      handler() {
        if(this.$route.hash === '#document'){
          return this.currentTab = this.tabs[0].name
        }
        else if(this.$route.hash === '#youtube'){
          return this.currentTab = this.tabs[1].name
        }
      },
    }
  }

}
</script>
<style>
  .w-full-smart {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  .w-full-smart iframe {
    width: 100% !important;
    height: 100% !important;
  }
</style>
